import { Link } from 'gatsby'
import * as React from 'react'
import PropTypes from 'prop-types'

const HaveQuestionsLink = ({ to, children }) => {
  return (
    <Link
      className={
        'flex items-center justify-center text-sm md:text-base rounded-md border-2 border-black px-2 py-2 text-center font-bold uppercase text-black hover:border-red-700 hover:text-red-700'
      }
      to={to}
    >
      <h3>{children}</h3>
    </Link>
  )
}
HaveQuestionsLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const HaveQuestions = ({ heading = 'HAVE QUESTIONS?' }) => {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 uppercase watch-category">
      <h2 className="underline-title col-span-2 lg:col-span-3 xl:col-span-4 my-5">
        {heading || 'HAVE QUESTIONS?'}
      </h2>
      <HaveQuestionsLink to="/contact-us">CONTACT US</HaveQuestionsLink>
      <HaveQuestionsLink to="/returns">OUR GUARANTEE</HaveQuestionsLink>
      <HaveQuestionsLink to="/purchases">SELL TO US</HaveQuestionsLink>
      <HaveQuestionsLink to="/howto">HOW TO ORDER</HaveQuestionsLink>
      <HaveQuestionsLink to="/ourstory">OUR STORY</HaveQuestionsLink>
      <HaveQuestionsLink to="/testimonials">TESTIMONIALS</HaveQuestionsLink>
      <HaveQuestionsLink to="/faq">FAQ</HaveQuestionsLink>
      <HaveQuestionsLink to="/glossary">GLOSSARY</HaveQuestionsLink>
    </div>
  )
}

HaveQuestions.propTypes = {
  heading: PropTypes.string,
}

export default HaveQuestions
